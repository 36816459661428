<template>
	<div class="left" :style="{background:all.isbg}">
        <slot>
			<div>wwwwww</div>
		</slot>
	</div>
</template>

<script>

export default {
  name: '',
  props:{
        left:{
            type: Object,
            default:()=>{
                return {
                    isbg:"", // 
                }
            }
        },
  },
  data(){
    return {
		isbg:"", // 
    }
  },
  computed:{
      all(){
          let Obj = this.left
          if(!Obj.isbg){
              Obj.isbg = this.isbg
          }
          return Obj
      }
  },
  components: {

  },
  mounted(){
       

  },
  methods:{

  }
}
</script>

<style scoped>
	.left{
        width: 212px;
		background: #2f3035;
		position: fixed;
		left: 0;
		top: 61px;
		bottom: 59px;
		padding: 10px 2px 10px 10px;
		box-sizing: border-box;
		z-index: 1;
        border-right: 1px solid #e1dede;
        box-shadow: 4px 3px 15px 2px rgb(0 0 0 / 7%)

	}
</style>
