import Vue from 'vue'
import App from './App'
import router from './router'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

import store from './store'
Vue.config.productionTip = false;
let env = process.env.NODE_ENV;

sessionStorage.setItem('env', env);


console.log(process.env.VUE_APP_URL);


new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})