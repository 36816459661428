<template>
	<div>
        <heads :bf="bf"></heads>	
        <!-- <left></left> -->
        <router-view></router-view>
        <foot></foot>
	</div>
</template>

<script>
import heads from './head.vue'
import foot from './foot.vue'
import left from './left.vue'
export default {
  name: 'index',
  components: {
    foot,
    heads,
    left
  },
  data(){
	  return {
      bf:1
	  }
  },
  mounted(){

  },
  watch:{
    $route(to, from){
         if(to.path=='/login'|| to.path=='/search'){
           this.bf =1
         } else{
           this.bf =0
         }
    }
  },

  methods:{

  }
	  
  
}
</script>
