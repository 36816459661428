<!-- 倒计时弹出窗 -->
<template>
	<div class="box">
		<div class="bfs" :class="all.isShow ? 'shows' : 'hiddens' "></div>
		<div class="right" :class="all.isShow ? 'show' : 'hidden' ">
			<!-- <span class="count">{{count}}</span> -->
			<span class="text">{{all.text}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'index',
		props:{
		    sideslip:{
		      type: Object,
		      default:()=>{
		        return {
		          text:"",            
				  dataTime: 2,
				  isShow:false,
		        //   tipsCallback:()=>{},         // 回调
		        }
		      }
		    },
		},
		data() {
			return {
				isShow: false,
				text:"",
				dataTime: 2,
				count: '', 
				timer: null,
				// tipsCallback:()=>{},         // 回调
			}
		},
			
		computed:{
			// 默认值 非空判断
			all(){
				let Obj = this.sideslip		
				if(Obj.isShow === true){
					this.callback(Obj.dataTime)
				}
				if(!Obj.text){
				    Obj.text = this.text
				}
				if(!Obj.isShow){
				    Obj.isShow = this.isShow
				}
				if(!Obj.dataTime){
				    Obj.dataTime = this.dataTime
				}
				
				return Obj
			}
		},
		mounted() {
		
		},
	
		methods: {
			callback (val){
				const dataTime = val;  //倒计时60秒
				if (!this.timer) {
					this.count = dataTime;
					this.timer = setInterval(() => {
					  if (this.count > 1 && this.count <= dataTime) {
						this.count--;
					  } else {
						this.all.isShow =false
						clearInterval(this.timer);
						this.timer = null;
					  }
					}, 1000);
				}
			},
	
		}
	}
</script>


<style scoped>
	.right {
		position: fixed;
		right: 0;
		min-width: 100px;
		text-align: center;
		line-height: 36px;
		padding: 0 12px;
		box-sizing: border-box;
		border-radius: 8px 0 0 8px;
		height: 36px;
		background: #fb7299;
		z-index: 999;
		margin-top: 12px;
		top: 80px;
	}
	.show {
		transform: translateX(0);
		transition: all .4s;
	}
	.hidden {
		transform: translateX(100%);
		transition: all .5s;
	}
	.shows{
		display: block;
		transition: all .3s;
	}
	.hiddens{
		display: none;
		transition: all .3s;
	}
	.count{
		font-size: 10px;
		color: #31C27C;
	}
	.text{
		margin-left: 4px;
		color: #fff;
	}
	.bfs{
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,0.24);
		z-index: 99;
	}
</style>
