import { render, staticRenderFns } from "./imgIng.vue?vue&type=template&id=0589c688&scoped=true&"
import script from "./imgIng.vue?vue&type=script&lang=js&"
export * from "./imgIng.vue?vue&type=script&lang=js&"
import style0 from "./imgIng.vue?vue&type=style&index=0&id=0589c688&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0589c688",
  null
  
)

export default component.exports