<template>
    <div id="app">
        <router-view v-if="isRouterAlive" />
    </div>
</template>

<script>
export default {
    name: 'App',

    data() {
        return {
            toJSON: '',
            isRouterAlive: true,
        }
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    created() {
        // this.$nextTick(() => {
        //     // 禁用右键
        //     document.oncontextmenu = new Function("event.returnValue=false");
        //     // 禁用选择
        //     document.onselectstart = new Function("event.returnValue=false");
        // });

        // this.stopF5Refresh()
    },

    mounted() {
        window.addEventListener("beforeunload", (e) => {
            // this.beforeunloadHandler(e);
        });
    },
    destroyed() {
        window.removeEventListener("beforeunload", (e) => {
            this.beforeunloadHandler(e);
        });
    },
    methods: {
        reload() {
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        }, 
        stopF5Refresh() {
            document.onkeydown = function (e) {
                var evt = window.event || e;
                var code = evt.keyCode || evt.which;
                //屏蔽F1---F12
                if (code > 111 && code < 124) {
                    if (evt.preventDefault) {
                        evt.preventDefault();
                    } else {
                        evt.keyCode = 0;
                        evt.returnValue = false;
                    }
                }
            };
            //禁止鼠标右键菜单
            document.oncontextmenu = function () {
                alert("休想看我地址!门都没有！")
                return false;
            };
            //阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
            history.pushState(null, null, window.location.href);
            window.addEventListener("popstate", function () {
                history.pushState(null, null, window.location.href);
            });
        },
        beforeunloadHandler(e) {
            e = e || window.event;
            if (e) {
                e.returnValue = "您是否确认离开了吗";
            }
            return "您是否确认离开海豚dj了吗";
        },

    },

}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

body {
    font-size: 12px;
    background-color: #f4f5f8;
}
</style>
