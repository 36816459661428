import Vue from 'vue'
import Router from 'vue-router'
import componentAll from '../components/common/index/index.vue' // 全局 头部/尾部

Vue.use(Router)
export default new Router({
    mode: 'history',
    routes: [
        {
           path: '/',
           component: componentAll,
           children:[
             // 首页

             {
                path: '/',
                name: '',
                component: () => import('../views/index/index.vue'),
            },
            {
              path: '/login',
              name: 'login',
              component: () => import('../views/login/index.vue'),
            },
            {
              path: '/updata',
              name: 'updata',
              component: () => import('../views/updata/index.vue'),
            },
            {
              path: '/user',
              name: 'user',
              component: () => import('../views/user/index.vue'),
            },
            {
              path: '/album',
              name: 'album',
              component: () => import('../views/album/index.vue'),
            },
            {
              path: '/play',
              name: 'play',
              component: () => import('../views/play/index.vue'),
            },
            {
              path: '/search',
              name: 'search',
              component: () => import('../views/search/index.vue'),
            },
            // 测试 
            {
              path: '/cs',
              name: 'cs',
              component: () => import('../views/cs.vue'),
            },
          
          
          
         
           ]
         },
     ]
})

















