<template>
	<div>
		<img class="imgs" :src="url" />
	</div>
</template>
<script>
	export default {
		props: ["src"], // 父组件传过来所需的url
		data() {
			return {
				url: require('../../assets/haitun.png'), // 先加载loading.gif
			};
		},
		watch: {
			src(n, o) {
				this.url = require('../../assets/haitun.png')
				var newImg = new Image();
				newImg.src = n;
				newImg.onerror = () => {
					// 图片加载错误时的替换图片
					newImg.src = require('../../assets/haitun.png');
				};
				newImg.onload = () => {
					// 图片加载成功后把地址给原来的img
					this.url = newImg.src;
				};
			},
		},
		mounted() {
			var newImg = new Image();
			newImg.src = this.src;
			newImg.onerror = () => {
				// 图片加载错误时的替换图片
				newImg.src = require('../../assets/haitun.png');
			};
			newImg.onload = () => {
				// 图片加载成功后把地址给原来的img
				this.url = newImg.src;
			};
		},
	};
</script>
<style scoped>
	.imgs{
		width: 100%;
		height:100%;
		
	}
</style> 
