import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const state = { 
  
  isShow: true,
  login: window.localStorage.getItem("logins"), // 登入状态
};

const getters = {


};
const mutations = {
  getLogin(state, t) {
    window.localStorage.setItem("logins", t)
    state.login = t
  },
  loding(state, t) {
    state.isShow = t
  },

};
const actions = {


};

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});

export default store;

