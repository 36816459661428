<template>
	<div class="header">
		<div class="header_son">
			<div class="logo" @click="btnIndex()">
				<!-- logo -->
				<!-- {{this.$store.state.login}} -->
				Haitundj
				
			</div>
			<div class="logo_input" :class="bf==1? 'bf':''">
				<input class="input_text" v-model="search" type="text" placeholder="搜索歌名称">
				<span class="btn_text" @click="btnSearch()">搜一下</span>
			</div>


			<div class="login">
				<div v-if="!this.$store.state.login">
					<span @click="btnLogin()">注册/登入</span>
				</div>
				<div v-else>
					<!-- <div class="user_all"> -->
						<!-- <imgIng :src="'http://82.157.140.72/test'+ this.$store.state.imgs" alt=""></imgIng>	 -->
						<!-- <div class="user_all_hover"> -->
							<!-- <div class="user_all_list" @click="btnUser()">个人中心</div>
							<div class="user_all_list" @click="btnCollection()">我的收藏</div> -->
							<!-- <div class="user_all_list" @click="btnOut()">退出</div> -->
						<!-- </div> -->
					<!-- </div> -->
					<div class="collection" @click="btnOut()">退出</div>
				</div>
			</div>
			<div class="collection" @click="btnUpdata()">上传</div>
			<!-- <div class="collection" @click="btnCollection()">收藏</div> -->
			<div class="collection" @click="btnUser()">个人中心</div>
		</div>
		<div style="clear: both;"></div>
		<sideslip :sideslip="sideslip"></sideslip>
	</div>
</template>

<script>
import sideslip from '../../common/tips.vue'
import imgIng from '../../../components/common/imgIng.vue'

export default {
	name: '',
	props:["bf"],
	data() {
		return {
			search: "",
			sideslip:{
				text: "请去登入",
                isShow: false,
                dataTime: 1
			},
			imgs:"",
		}
	},

	components: {
		sideslip,
		imgIng
	},
	mounted() {
		// console.log(localStorage.getItem('userInfo')&&JSON.parse(localStorage.getItem('userInfo')).user_avatar,99999);
	},
	methods: {
		btnIndex(){
			this.$router.push({path: '/'})
		},
		btnSearch() {
			if(!this.search){
				this.sideslip.isShow =true
				this.sideslip.text ="请输入搜索内容"
			} else {
				this.$router.push({path: '/search' ,query:{search:this.search}})
				this.search =''
			}
			
		},
		btnLogin() {
			this.$router.push({path: '/login'})
		},
		btnUser() {
			if(!this.$store.state.login){
				this.sideslip.isShow=true;
			}else {
				this.$router.push({path: '/user'})
			}
		},
		btnCollection() {

		},
		btnOut() {
			this.$router.push({path:'/login',query: {}})
			this.$store.commit('getLogin', false);
			window.localStorage.removeItem("userInfo")
			window.localStorage.removeItem("logins")
			this.$store.commit('imgsFun', '');
		},
		btnUpdata() {
			if(!this.$store.state.login){
				this.sideslip.isShow=true;
			}else {
				this.$router.push({path: '/updata'})
			}
			
		},
		btnCollection() {

		},

	},

}
</script>

<style scoped>
.header {
	width: 100%;
	height: 59px;
	background: #fff;
	border-bottom: 1px solid #e2e2e2;
	box-shadow: 0 0 6px rgb(0 0 0 / 6%);
	position: fixed;
	top: 0;
	z-index: 9;
}

.header_son {
	width: 100%;
	height: 100%;
	position: relative;
}

.logo {
	width: 120px;
	height: 48px;
	background: #fb7299;
	margin: 6px 8px;
	float: left;
	color: #fff;
	text-align: center;
	line-height: 48px;
	font-size: 16px;
	border-radius: 4px;
	cursor: pointer;

}

.logo_input {
	width: 380px;
	height: 34px;
	padding: 3px;
	background: #fb7299;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	margin-left: 100px;
	border-radius: 4px;
}
.logo_input.bf{
	margin-left: 0px;
}

.input_text {
	width: 320px;
	height: 100%;
	border: none;
	padding-left: 9px;
	box-sizing: border-box;
	border-radius: 4px;
	outline: none;
	cursor: pointer;
	resize: none;
}

.btn_text {
	margin-left: 12px;
	text-align: center;
	color: #fff;
}

.login {
	margin-right: 12px;
	float: right;
	line-height: 59px;
}

.up_text {
	float: right;
	margin-right: 12px;
	cursor: pointer;
	margin-top: 12px;
	font-size: 14px;
	width: 52px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	border-radius: 2px;
}

.collection {
	line-height: 59px;
	float: right;
	margin-right: 12px;
	font-size: 14px;
	cursor: pointer;
}
.collection:hover{
	color: #fb7299;
}


.user_all {
	width: 32px;
	height: 32px;
	background: #fb7299;
	border-radius: 50%;
	margin-top: 14px;
	position: relative;
}

.user_all_hover {
	width: 100px;
	min-height: 10px;
	background: #fff;
	position: absolute;
	top: 32px;
	left: 50%;
	transform: translate(-50%);
	border-bottom: 1px solid #e2e2e2;
	box-shadow: 0 0 6px rgb(0 0 0 / 6%);
	border-radius: 4px;
	display: none;
}

.user_all:hover .user_all_hover {
	display: block;
}

.user_all_img {
	width: 100%;
}

.user_all_list {
	width: 100%;
	height: 32%;
	line-height: 32px;
	padding: 0 0 0 12px;
	box-sizing: border-box;
	cursor: pointer;
}

.user_all_list:hover {
	background: #f0f7f3;
}
</style>
